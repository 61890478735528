<div class="calendar-container">
  <label class="input-label">
    {{ label }}
    <span *ngIf="required" class="required-label">*</span>
  </label>
  <p-calendar
    [formControl]="control"
    [iconDisplay]="'input'"
    [showIcon]="true"
    [appendTo]="'body'"
    dateFormat="yy-mm-dd"
  />

  <div *ngIf="control.invalid && control.touched" class="error-message">
    {{ label ? label : error }} is required.
  </div>
</div>

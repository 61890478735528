import { Component } from '@angular/core';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { ButtonComponent } from '../../Components/button/button.component';
import { SharedModule } from '../../shared.module';
import { DropDownType, SchemeType, TableCols } from '../../types';
import {
  ACTIVITES_COLS,
  REGULATOR_LIST,
  SCHEME_COLS,
  STATE_LIST,
} from '../../../constants';
import { InputComponent } from '../../Components/input/input.component';
import { DropdownComponent } from '../../Components/dropdown/dropdown.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SchemeService } from './scheme.services';
import { MessageService } from 'primeng/api';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-scheme',
  standalone: true,
  templateUrl: './scheme.component.html',
  styleUrl: './scheme.component.scss',
  providers: [MessageService],
  imports: [
    SearchFieldComponent,
    ButtonComponent,
    SharedModule,
    InputComponent,
    DropdownComponent,
  ],
})
export class SchemeComponent {
  cols: TableCols[] = SCHEME_COLS;
  SchemeList: SchemeType[] = [];
  addDialog: boolean = false;
  formData!: FormGroup;
  editForm: boolean = false;
  StateList: DropDownType[] = STATE_LIST;
  RegulatorList: DropDownType[] = REGULATOR_LIST;
  onHover: number = 0;
  isLoading: boolean = true;
  skeletonRows = new Array(10);
  deleteDialog: boolean = false;
  rowId: number = 0;
  rowData: SchemeType | object = {};
  deleteActivtyDialog: boolean = false;
  onSaveLoad: boolean = false;
  schemeDataClone: SchemeType[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private service: SchemeService,
    private messageService: MessageService,
    private router: Router
  ) {
    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      regulator: ['', Validators.required],
      states: [[], Validators.required],
    });
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  async ngOnInit() {
    this.SchemeList = await this.schemeData();
  }

  async schemeData(): Promise<SchemeType[]> {
    try {
      const res = await this.service.fetchSchemeData();
      if (res) this.isLoading = false;
      const schemes = res.map((scheme: SchemeType) => ({
        ...scheme,
        states: scheme.states.split(','),
      }));
      this.schemeDataClone = this.sortByName(schemes);
      return this.sortByName(schemes);
    } catch (error: any) {
      this.isLoading = false;
      this.errorToast('Failed to List Scheme');
      return [];
    }
  }

  private sortByName(zones: SchemeType[]): SchemeType[] {
    return zones.slice().sort((a, b) => a.name.localeCompare(b.name));
  }

  async handleSearch(searchVal: string): Promise<void> {
    this.SchemeList = this.schemeDataClone.filter(
      (item) =>
        item.name.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.regulator.toLowerCase().includes(searchVal.toLowerCase())
    );
  }

  handleAddNew(): void {
    this.addDialog = true;
    this.formData.reset();
    this.editForm = false;
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  async handleEdit(rowData: SchemeType): Promise<void> {
    this.editForm = true;
    this.addDialog = true;
    this.rowData = rowData;

    this.formData.patchValue({
      name: rowData.name,
      states: rowData.states,
      regulator: rowData.regulator,
    });
  }

  closeDialog(): void {
    this.addDialog = false;
    this.deleteDialog = false;
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        ...this.formData.value,
        states: this.formData.value.states.join(','),
      };
      const res = await this.service.createSchemeData(payload);
      if (res) {
        this.addDialog = false;
        this.onSaveLoad = false;
        this.successToast('Scheme Created Successfully');
        this.SchemeList = await this.schemeData();
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        ...this.rowData,
        ...this.formData.value,
        states: this.formData.value.states.join(','),
      };

      const res = await this.service.updateSchemeData(payload);
      if (res) {
        this.SchemeList = await this.schemeData();
        this.onSaveLoad = false;
        this.addDialog = false;
        this.successToast('Scheme Updated Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async handleDelete(rowData: SchemeType): Promise<void> {
    this.deleteDialog = true;
    this.rowId = rowData.id;
  }

  async onDeleteScheme(): Promise<void> {
    this.onSaveLoad = false;
    try {
      const res = await this.service.deleteSchemeData(this.rowId);
      if (res) {
        this.onSaveLoad = false;
        this.deleteDialog = false;
        this.SchemeList = await this.schemeData();
        this.successToast('Scheme Deleted Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  nagivateToActivities(rowData: SchemeType): void {
    const { id } = rowData;
    const params: NavigationExtras = { state: { rowData } };
    this.router.navigate([`scheme/${id}/activities`], params);
  }
}

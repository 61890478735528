import { Component } from '@angular/core';
import { NumberInputComponent } from '../../../Components/number-input/number-input.component';
import { SharedModule } from '../../../shared.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DropdownComponent } from '../../../Components/dropdown/dropdown.component';
import { CalendarComponent } from '../../../Components/calendar/calendar.component';
import { ButtonComponent } from '../../../Components/button/button.component';
import {
  DropDownType,
  FieldSetFieldMap,
  SchemeActivityType,
  SchemeType,
  TableCols,
} from '../../../types';
import {
  DEVICE_TYPE,
  DWELLING_TYPE,
  SIMULATE_COLS,
} from '../../../../constants';
import { InputComponent } from '../../../Components/input/input.component';
import { SimulateJobService } from './simulate-job.services';
import { MessageService } from 'primeng/api';
import moment from 'moment';
import { SchemeService } from '../scheme.services';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  selector: 'app-simulate-job',
  standalone: true,
  templateUrl: './simulate-job.component.html',
  styleUrl: './simulate-job.component.scss',
  providers: [MessageService],
  imports: [
    NumberInputComponent,
    SharedModule,
    DropdownComponent,
    CalendarComponent,
    ButtonComponent,
    InputComponent,
    InputNumberModule,
  ],
})
export class SimulateJobComponent {
  simulateDialog: boolean = false;
  formData!: FormGroup;
  cols: TableCols[] = SIMULATE_COLS;
  selectedRows!: SchemeActivityType;
  simulateList: SchemeType[] = [];
  calculationDialog: boolean = false;
  calculationForm!: FormGroup;
  deviceTypeList: DropDownType[] = DEVICE_TYPE;
  dwellingTypeList: DropDownType[] = DWELLING_TYPE;
  assessmentFields: any[] = [];
  Boolean: { name: string; key: string }[] = [
    { name: 'Yes', key: 'true' },
    { name: 'No', key: 'false' },
  ];
  isLoading: boolean = true;
  skeletonRows = new Array(5);
  deviceFrom!: FormGroup;
  outputDialog: boolean = false;
  certificateList: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private service: SimulateJobService,
    private messageService: MessageService,
    private fieldService: SchemeService
  ) {
    this.formData = this.formBuilder.group({
      postalCode: [0],
      deviceType: [''],
      dwellingType: [''],
      date: [''],
    });

    this.deviceFrom = this.formBuilder.group({
      CatelogueEntryId: ['', Validators.required],
    });
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  ngOnInit(): void {}

  async getSimulateJobData(): Promise<any[]> {
    try {
      const res = await this.service.fetchSimulateJobData(this.formData.value);
      if (res) this.isLoading = false;
      return res;
    } catch (error: any) {
      this.isLoading = false;
      this.errorToast(error.message);
      return [];
    }
  }

  async handleSimulate(): Promise<void> {
    this.simulateDialog = true;
    this.simulateList = await this.getSimulateJobData();
  }

  async handleCalculation(): Promise<void> {
    const payload = {
      schemeId: this.selectedRows.schemeId,
      activityId: this.selectedRows.id,
      date: moment(this.formData.value.date).format('DD/MM/YYYY'),
      postalCode: this.formData.value.postalCode,
    };
    try {
      const res = await this.service.fetchFieldDatas(payload);
      if (res) {
        this.getAssessmentFieldSet(res);
        this.calculationDialog = true;
        this.FieldMapForm();
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  private getAssessmentFieldSet(fieldData: any[]): void {
    this.assessmentFields = fieldData
      .filter(
        (item) =>
          item.fieldType !== 'Photo' &&
          item.fieldType !== 'DE_Placeholder' &&
          item.fieldType !== 'Signature'
      )
      .map((item) => {
        if (item.fieldType === 'DE_Picklist') {
          const optionValue = JSON.parse(item.fieldValues[0].values);
          return { ...item, optionValue };
        } else {
          return item;
        }
      });
  }

  async getFieldData(data: FieldSetFieldMap[]) {
    try {
      const res = await this.fieldService.fetchFieldMapData(
        this.selectedRows.schemeId,
        this.selectedRows.id,
        data[0].id
      );
      return res;
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  private FieldMapForm(): void {
    const formControls = this.assessmentFields.reduce((acc, item) => {
      const code = item.code;
      let initialValue = '';
      const field = item;

      if (field.fieldType === 'Constant') {
        initialValue = field.fieldValues[0].values;
      }

      acc[code] = [initialValue, Validators.required];
      return acc;
    }, {});

    this.calculationForm = this.formBuilder.group(formControls);
  }

  closeSimulateDialog(): void {
    this.simulateDialog = false;
  }

  closeCalculationDialog(): void {
    this.calculationDialog = false;
  }

  closeOutputDialog(): void {
    this.outputDialog = false;
  }

  async onCalculate(): Promise<void> {
    try {
      const payload = {
        activityId: this.selectedRows.id,
        activityName: this.selectedRows.name,
        postalCode: this.formData.value.postalCode,
        catelogueEntryId: this.deviceFrom.value.CatelogueEntryId,
        fieldValues: {
          ...this.calculationForm.value,
        },
      };
      const res = await this.service.calculateFieldValue(payload);
      if (res) {
        this.outputDialog = true;
        const data = [res];
        this.certificateList = data.flatMap((obj) =>
          Object.entries(obj).map(([label, value]) => ({ label, value }))
        );
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }
}

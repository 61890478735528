<p-toast />
<div class="page-container hand-out-document">
  <div class="header-container">
    <!-- <div class="header">Hand-Out Documents</div> -->
    <app-search-text (searchEvent)="handleSearch($event)" class="header" />
    <app-button
      label="Upload"
      (clickEvent)="handleNewUpload()"
      class="header"
    />
  </div>

  <p-table
    [columns]="cols"
    dataKey="id"
    [value]="HandOutDocumentList"
    [paginator]="true"
    [rows]="10"
    [(selection)]="selectedRows"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <!-- <th style="width: 4rem">
          <p-tableHeaderCheckbox />
        </th> -->
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>

      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <!-- <th class="skeleton" style="width: 4rem">
            <p-skeleton></p-skeleton>
          </th> -->
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <!-- <td>
          <p-tableCheckbox [value]="rowData" />
        </td> -->
        <td style="font-weight: 500">{{ rowData.title }}</td>
        <td>{{ rowData.version }}</td>
        <td>
          <a
            class="view-document"
            style="color: #235aff; cursor: pointer"
            (click)="downloadFile(rowData)"
          >
            View Document
          </a>
        </td>
        <td (click)="op.toggle($event)">
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-overlayPanel #op>
            <ng-template pTemplate="content">
              <div
                (mouseover)="handleHover(1)"
                (click)="handleEdit(rowData)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Edit</span>
              </div>
              <!-- <div
                (mouseover)="handleHover(2)"
                (click)="handleDownload()"
                class="list-item"
                [class.hover-bg-light-green]="onHover === 2"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-file-arrow-up" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Download</span>
              </div> -->
              <div
                (mouseover)="handleHover(3)"
                (click)="handleDelete(rowData)"
                class="list-item"
                [class.hover-bg-light-red]="onHover === 3"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Delete</span>
              </div>
            </ng-template>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>

    <ng-template
      pTemplate="emptymessage"
      *ngIf="HandOutDocumentList.length === 0"
    >
      <tr *ngIf="!isLoading">
        <td colspan="5">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog
    header="Header"
    [(visible)]="display"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header">
      <h3>{{ edit ? "Edit" : "Add" }}</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="formData">
        <div class="grid-container">
          <app-input
            label="Title"
            [control]="formData.get('title')"
            [required]="true"
          />
          <app-input
            label="Version"
            [control]="formData.get('version')"
            [required]="true"
          />
        </div>
        <label class="input-label">Document</label>
        <label
          (drop)="onFileDrop($event)"
          (dragover)="onDragOver($event)"
          (dragleave)="onDragLeave($event)"
          htmlFor="{fileType}"
        >
          <div class="file-text">Select or drag file</div>
          <input
            type="file"
            (change)="onFileSelected($event)"
            id="fileType"
            style="display: none"
          />
        </label>
        <div class="file-name" *ngIf="selectedFileName != null">
          {{ selectedFileName }}
          <span
            class="pi pi-times"
            style="color: #e71d36; margin-left: 1rem"
            (click)="removeFile()"
          ></span>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        *ngIf="!edit"
        label="Save"
        [disabled]="formData.invalid"
        (clickEvent)="onSave()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
      <app-button
        *ngIf="edit"
        label="Update"
        [disabled]="formData.invalid"
        (clickEvent)="onUpdate()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="deleteDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '32vw' }"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="content">
      <h1>Are you sure you want to delete this Hand out Document ?</h1>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        label="Delete"
        (clickEvent)="onDeleteDocument()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>
</div>

import { Component, ViewChild } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { SearchFieldComponent } from '../../../Components/searchField/search-text.component';
import { ButtonComponent } from '../../../Components/button/button.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SchemeService } from '../scheme.services';
import { MessageService } from 'primeng/api';
import {
  ActivityFieldSet,
  FieldSetFieldMap,
  ManageFieldData,
  SchemeActivityType,
  TableCols,
  DocumentType,
} from '../../../types';
import {
  ACTIVITES_EDIT,
  ACTIVITY_DOCUMENTS_COLS,
  FIELD_SET_COLS,
  PROJECT_STATUS,
} from '../../../../constants';
import { CalendarComponent } from '../../../Components/calendar/calendar.component';
import { FieldService } from '../manage-fields/field.services';
import moment from 'moment';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { InputComponent } from '../../../Components/input/input.component';
import { DropdownComponent } from '../../../Components/dropdown/dropdown.component';
import { HandOutDocumentsComponent } from '../hand-out-documents/hand-out-documents.component';
import { DocumentsService } from '../hand-out-documents/handout-document.services';

@Component({
  selector: 'app-field-sets',
  standalone: true,
  templateUrl: './field-sets.component.html',
  styleUrl: './field-sets.component.scss',
  providers: [MessageService],
  imports: [
    SharedModule,
    SearchFieldComponent,
    ButtonComponent,
    CalendarComponent,
    CdkDropList,
    CdkDrag,
    CdkDragPlaceholder,
    InputComponent,
    DropdownComponent,
    HandOutDocumentsComponent,
  ],
})
export class FieldSetsComponent {
  cols: TableCols[] = FIELD_SET_COLS;
  display: boolean = false;
  editFields: boolean = false;
  onHover: number = 0;
  isLoading: boolean = true;
  skeletonRows = new Array(10);
  formData!: FormGroup;
  documentData!: FormGroup;
  onSaveLoad: boolean = false;
  fieldSetList: ActivityFieldSet[] = [];
  rowData!: ActivityFieldSet;
  deleteDialog: boolean = false;
  activtiesData!: SchemeActivityType;
  schemeId: number = 0;
  fieldCols: TableCols[] = ACTIVITES_EDIT;
  fieldList: any[] = [];
  selectedRows: string[] = [];
  fieldsDialog: boolean = false;
  FieldsList: ManageFieldData[] = [];
  fieldDataClone: ManageFieldData[] = [];
  @ViewChild('opAssessment') opAssessment!: OverlayPanel;
  @ViewChild('opInstallation') opInstallation!: OverlayPanel;
  assessmentField: FieldSetFieldMap[] = [];
  installationField: FieldSetFieldMap[] = [];
  isFieldLoading: boolean = true;
  edit: boolean = false;
  selectedFileName: string | null = null;
  DocumentCols: TableCols[] = ACTIVITY_DOCUMENTS_COLS;
  documentDialog: boolean = false;
  projectStatus: any[] = PROJECT_STATUS;
  documentList: any[] = [];
  handOutDocuments: any[] = [];

  constructor(
    private service: SchemeService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private fieldService: FieldService,
    private documentService: DocumentsService
  ) {
    this.initialFormValue();
    this.initialDocumentData();
  }

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  dropFields(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.fieldList, event.previousIndex, event.currentIndex);
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  formatFieldType(fieldType: string): string {
    return fieldType.replace(/_/g, '-');
  }

  initialFormValue(): void {
    this.formData = this.formBuilder.group({
      valid_from: ['', Validators.required],
    });
  }

  initialDocumentData(): void {
    this.documentData = this.formBuilder.group({
      documents: [''],
    });
  }

  async ngOnInit(): Promise<void> {
    this.activtiesData = history.state.activity;
    this.schemeId = history.state.scheme.id;
    this.fieldSetList = await this.getFieldSetData();
    await this.getActivityDocuments();
  }

  private async getFieldList(): Promise<ManageFieldData[]> {
    try {
      const res = await this.fieldService.fetchActivityFields();
      this.fieldDataClone = res;
      if (res) this.isFieldLoading = false;
      return res;
    } catch (error: any) {
      this.isFieldLoading = false;
      this.errorToast(error.message);
      return [];
    }
  }

  private async getFieldSetData(): Promise<ActivityFieldSet[]> {
    try {
      const res = await this.service.fetchFieldSetData(
        this.schemeId,
        this.activtiesData.id
      );
      if (res) this.isLoading = false;
      return res;
    } catch (error: any) {
      this.isLoading = false;
      this.errorToast(error.message);
      return [];
    }
  }

  private async getFieldMapData(
    activityId: number,
    fieldsetId: number
  ): Promise<FieldSetFieldMap[]> {
    try {
      const res = await this.service.fetchFieldMapData(
        this.schemeId,
        activityId,
        fieldsetId
      );
      return res;
    } catch (error: any) {
      this.errorToast(error.message);
      return [];
    }
  }

  async onAssessmentField(
    event: Event,
    rowData: ActivityFieldSet
  ): Promise<void> {
    event.stopPropagation();
    this.opAssessment.show(event);
    const data = await this.getFieldMapData(
      rowData.schemeActivityId,
      rowData.id
    );
    this.assessmentField = data.filter((item) => item.stage === 'ASSESSMENT');
  }

  clearInstallation() {
    this.opInstallation.hide();
  }

  clearAssessment() {
    this.opAssessment.hide();
  }

  async onInstallationField(
    event: Event,
    rowData: ActivityFieldSet
  ): Promise<void> {
    event.stopPropagation();
    this.opInstallation.show(event);
    const data = await this.getFieldMapData(
      rowData.schemeActivityId,
      rowData.id
    );
    this.installationField = data.filter(
      (item) => item.stage === 'INSTALLATION'
    );
  }

  navigateBack(): void {
    window.history.back();
  }

  closeDialog(): void {
    this.display = false;
    this.deleteDialog = false;
    this.documentDialog = false;
  }

  handleAddNew(): void {
    this.display = true;
    this.editFields = false;
    this.initialFormValue();
    this.fieldList = [];
  }

  async handleSearch(search: string): Promise<void> {
    const searchVal = search.trim();
    this.FieldsList = this.fieldDataClone.filter(
      (item) =>
        item.code.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.fieldType.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.fieldStage.toLowerCase().includes(searchVal.toLowerCase())
    );
  }

  async handleEdit(rowData: ActivityFieldSet): Promise<void> {
    this.display = true;
    this.editFields = true;
    this.rowData = rowData;
    this.formData.patchValue({
      valid_from: rowData.dateFrom,
    });
    await this.prefillFields(rowData.schemeActivityId, rowData.id);
  }

  private async prefillFields(activityId: number, id: number): Promise<void> {
    const fields = await this.getFieldMapData(activityId, id);
    this.fieldList = fields.map((c) => c.field);
  }

  async handleDelete(rowData: ActivityFieldSet): Promise<void> {
    this.deleteDialog = true;
    this.rowData = rowData;
  }

  async onAmend(): Promise<void> {
    const { valid_from } = this.formData.value;
    const valid_date = moment(valid_from).format('YYYY-MM-DD');
    const dateFrom = this.rowData.dateFrom;

    if (valid_date === dateFrom) {
      await this.onUpdate();
    } else {
      await this.onSave();
    }
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        name: this.activtiesData.name,
        dateFrom: moment(this.formData.value.valid_from).format('YYYY-MM-DD'),
      };

      const res = await this.service.createFieldSetData(
        this.schemeId,
        this.activtiesData.id,
        payload
      );

      if (res) {
        this.createFieldMap(res.id);
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async createFieldMap(fieldSetId: number): Promise<void> {
    try {
      const fieldMapValue = this.extractFieldSet(this.fieldList, fieldSetId);
      const res = await this.service.createFieldMapData(
        this.schemeId,
        this.activtiesData.id,
        fieldSetId,
        fieldMapValue
      );
      if (res) {
        this.onSaveLoad = false;
        this.successToast('Field Sets Created Successfully');
        this.display = false;
        this.fieldSetList = await this.getFieldSetData();
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    try {
      this.updateFieldMap(this.rowData.id);
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async updateFieldMap(fieldSetId: number): Promise<void> {
    try {
      const fieldMapValue = this.extractFieldSet(this.fieldList, fieldSetId);
      const res = await this.service.updateFieldMapData(
        this.schemeId,
        this.activtiesData.id,
        fieldSetId,
        fieldMapValue
      );
      if (res) {
        this.onSaveLoad = false;
        this.successToast('Field Sets Updated Successfully');
        this.display = false;
        this.fieldSetList = await this.getFieldSetData();
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  private extractFieldSet(list: any[], fieldSetId: number): any[] {
    return list.map((item) => ({
      fieldId: item.id,
      stage:
        item.fieldStage === 'InstallField'
          ? 'INSTALLATION'
          : item.fieldStage === 'AssessmentField'
          ? 'ASSESSMENT'
          : item.fieldStage,
      fieldSetId,
    }));
  }

  async onDeleteFieldSet(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const res = await this.service.deleteFieldSetData(
        this.schemeId,
        this.activtiesData.id,
        this.rowData.id
      );
      if (res) {
        this.fieldSetList = await this.getFieldSetData();
        this.deleteDialog = false;
        this.onSaveLoad = false;
        this.successToast('Field Set Deleted Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async addtoFields(): Promise<void> {
    this.fieldsDialog = true;
    this.selectedRows = [];
    this.FieldsList = await this.getFieldList();
    this.selectedRows = this.fieldList;
  }

  async onFieldSave(): Promise<void> {
    this.fieldList = this.selectedRows;
    this.fieldsDialog = false;
  }

  closFieldDialog(): void {
    this.fieldsDialog = false;
  }

  // Documents
  async handleAddDocument(): Promise<void> {
    this.documentDialog = true;
    await this.getDocumentsData();
    if (this.documentList.length > 0) {
      this.documentData.patchValue({
        documents: this.documentList.map((c) => c.id),
      });
    }
  }

  async onDocumentSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const data = await this.documentService.createActivityDocuments(
        this.activtiesData.id,
        this.documentData.value.documents
      );
      if (data) {
        this.onSaveLoad = false;
        this.documentDialog = false;
        await this.getActivityDocuments();
        this.successToast('Hand Out Documents added Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async downloadFile(rowData: DocumentType): Promise<void> {
    await this.documentService.getDocumentFile(rowData.id, rowData.title);
  }

  private async getActivityDocuments(): Promise<DocumentType[]> {
    try {
      const data = await this.documentService.fetchActivityDocuments(
        this.activtiesData.id
      );

      if (data) {
        this.documentList = data;
        this.isLoading = false;
      }
      return data;
    } catch (error: any) {
      this.errorToast(error.message);
      this.isLoading = false;
      return [];
    }
  }

  private async getDocumentsData(): Promise<DocumentType[]> {
    try {
      const data = await this.documentService.fetchDocumentsData();

      if (data) {
        this.handOutDocuments = data;
        this.isLoading = false;
      }
      return data;
    } catch (error: any) {
      this.errorToast(error.message);
      this.isLoading = false;
      return [];
    }
  }
}

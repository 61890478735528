<div class="dropdown-container">
  <label class="input-label">
    {{ label }}
    <span *ngIf="required" class="required-label">*</span>
  </label>
  <p-dropdown
    [options]="options"
    [filter]="filter"
    [formControl]="control"
    [placeholder]="placeholder"
    [appendTo]="'body'"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [showClear]="showClear"
  />
  <div *ngIf="control.invalid && control.touched" class="error-message">
    {{ label ? label : error }} is required.
  </div>
</div>

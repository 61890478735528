import { Component, OnInit } from '@angular/core';
import { SideBarList } from '../../../constants';
import { Router } from '@angular/router';
import { SharedModule } from '../../shared.module';
import { SideBarItem, SubMenu } from '../../types';

@Component({
  selector: 'app-side-bar',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  sideBar: any[] = SideBarList;
  activeTab: string = 'Dashboard';
  activeSubTab: string = '';
  subTabVisible: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    const routeName = this.getCurrentRouteName();
    this.updateActiveTab(routeName);
  }

  getCurrentRouteName(): string {
    return location.pathname.split('/')[1];
  }

  updateActiveTab(routeName: string): void {
    if (routeName === 'scheme' && location.pathname.split('/').length === 3) {
      this.updateActiveTabForScheme();
    } else {
      const currentMenu = this.sideBar.find((menu) => menu.key === routeName);
      this.activeTab = currentMenu ? currentMenu.label : 'Dashboard';
    }
  }

  updateActiveTabForScheme(): void {
    this.activeTab = '';
    const schemeRouteName = location.pathname.split('/')[2] || '';
    const currentSubMenu = this.getSchemeSubMenu(schemeRouteName);
    this.activeSubTab = currentSubMenu ? currentSubMenu.label : '';
    this.subTabVisible = true;
  }

  getSchemeSubMenu(routeName: string) {
    const schemeMenu =
      this.sideBar.find((item) => item.key === 'scheme').subMenu || [];
    return schemeMenu.find((menu: SubMenu) => menu.key === routeName);
  }

  navigateTo(route: string, label: string): void {
    this.activeSubTab = '';
    this.activeTab = label;
    this.router.navigate([route]);
  }

  navigateToSubmenu(label: string, route: string): void {
    this.activeTab = '';
    this.activeSubTab = label;
    this.router.navigate([route]);
  }

  toggleSubMenu(item: SideBarItem): void {
    item.subMenuVisible = !item.subMenuVisible;
    this.subTabVisible = item.subMenuVisible;
  }
}

<!-- <div class="dashboard-container">
  <div class="aggregator">
    <div class="header-container">
      <div class="header">
        <img src="assets/SidebarIcon/Aggregator.svg" />
        <label style="color: #474d66">Aggregator</label>
      </div>
      <h1>120</h1>
    </div>
    <div class="horizontal-line"></div>
    <div class="footer-container">
      <img src="assets/dashboard.icon.svg" />
      <div>
        <h1 style="color: #00ad00">5 +</h1>
        <div style="color: #8c97a8">from last week</div>
      </div>
    </div>
  </div>
  <div class="project">
    <div class="header-container">
      <div class="header">
        <img src="assets/SidebarIcon/Catalogue.svg" />
        <label style="color: #474d66">Projects</label>
      </div>
      <h1>300</h1>
    </div>
    <div class="horizontal-line"></div>
    <div class="footer-container">
      <img src="assets/dashboard2.icon.svg" />
      <div>
        <h1 style="color: #00ad00">25 +</h1>
        <div style="color: #8c97a8">from last week</div>
      </div>
    </div>
  </div>
  <div class="installers">
    <div class="header-container">
      <div class="header">
        <img src="assets/SidebarIcon/Aggregator.svg" />
        <label style="color: #474d66">Installers</label>
      </div>
      <h1>50</h1>
    </div>
    <div class="horizontal-line"></div>
    <div class="footer-container">
      <img src="assets/dashboard1.icon.svg" />
      <div>
        <h1 style="color: #00ad00">10 +</h1>
        <div style="color: #8c97a8">from last week</div>
      </div>
    </div>
  </div>
</div>

<div class="dashboard-table-container">
  <p-table
    [columns]="cols"
    [value]="data"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
    dataKey="id"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td>{{ rowData.name }}</td>
        <td style="color: #1950fd">{{ rowData.new }}</td>
        <td style="color: #741fb7">{{ rowData.assessments }}</td>
        <td style="color: #ef6500">{{ rowData.installation }}</td>
        <td style="color: #f500b0">{{ rowData.audit }}</td>
        <td style="color: #00ad00">{{ rowData.complete }}</td>
      </tr>
    </ng-template>
  </p-table>
</div> -->

<div class="certificate-container">
  <div style="display: flex; justify-content: space-between">
    <div class="heading">Certificate Counts</div>
    <p-dropdown
      [options]="installersList"
      [(ngModel)]="installer"
      optionLabel="name"
      optionValue="id"
      placeholder="Select"
      (onChange)="onCertificateTypeChange($event)"
    />
  </div>
  <p-chart
    type="bar"
    [data]="installerCertificateData"
    [options]="basicOptions"
  />
</div>

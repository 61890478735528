import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Router, RouterOutlet } from '@angular/router';
import ROUTES from '../routes.const';
import { TOKEN } from '../constants';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  ripple: boolean = false;

  constructor(private primengConfig: PrimeNGConfig, private router: Router) {
    this.useAuth();
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.ripple = true;
  }

  useAuth(): boolean {
    const data = localStorage.getItem(TOKEN);

    if (data) {
      const isAuthenticated = data;
      return true;
    } else {
      this.router.navigate([ROUTES.LOGIN]);
      return false;
    }
  }

  title = 'themis-admin';
}

import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';
import { FormFieldsType } from '../../types';

@Injectable({ providedIn: 'root' })
export class FormsService {
  constructor(private api: ApiService) {}

  async fetchFormsData(): Promise<any> {
    try {
      const data: FormFieldsType[] = await firstValueFrom(
        this.api.get('api/form')
      );
      const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
      return sortedData;
    } catch (error) {
      throw error;
    }
  }

  async fetchFormFieldsData(): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.get('api/field/purpose?fieldPurpose=form')
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async createFormData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.post('api/form', payload));
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateFormData(payload: any): Promise<any> {
    try {
      const data = await firstValueFrom(
        this.api.put(`api/form/${payload.id}`, payload)
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async deleteFormData(id: number): Promise<any> {
    try {
      const data = await firstValueFrom(this.api.delete(`api/form/${id}`));
      return data;
    } catch (error) {
      throw error;
    }
  }
}

import { Component } from '@angular/core';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { ButtonComponent } from '../../Components/button/button.component';
import { SharedModule } from '../../shared.module';
import { AggregatorType, SchemeType, TableCols } from '../../types';
import { AGGREGATOR_COLS, USER_COLS } from '../../../constants';
import { InputComponent } from '../../Components/input/input.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AggregatorService } from './aggregator.service';
import { MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { ApiService } from '../../Services/common-services.service';
import { NumberInputComponent } from '../../Components/number-input/number-input.component';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { DisabledInputComponent } from '../../Components/disabled-input/disabled-input.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-aggregator',
  standalone: true,
  templateUrl: './aggregator.component.html',
  styleUrl: './aggregator.component.scss',
  providers: [MessageService, ApiService],
  imports: [
    SearchFieldComponent,
    ButtonComponent,
    SharedModule,
    InputComponent,
    NumberInputComponent,
    HttpClientModule,
    HttpClientJsonpModule,
    DisabledInputComponent,
  ],
})
export class AggregatorComponent {
  display: boolean = false;
  cols: TableCols[] = AGGREGATOR_COLS;
  userCols: TableCols[] = USER_COLS;
  AggregatorList: AggregatorType[] = [];
  onHover: number = 0;
  edit: boolean = false;
  formData!: FormGroup;
  Activities: any[] = [];
  AggregatorLogo: string | null = null;
  imageUrl: string | null = null;
  userData!: FormGroup;
  isLoading: boolean = true;
  skeletonRows = new Array(10);
  rowData: any;
  deleteDialog: boolean = false;
  onSaveLoad: boolean = false;
  inviteDialog: boolean = false;
  userList: any[] = [];
  userRowData: any;
  deleteUser: boolean = false;
  logoData!: FormGroup;
  AggregatorListClone: AggregatorType[] = [];
  searchText: string = '';
  filteredAddress: any[] = [];

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  constructor(
    private formBuilder: FormBuilder,
    private service: AggregatorService,
    private messageService: MessageService,
    private api: ApiService,
    private sanitizer: DomSanitizer
  ) {
    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      ABN: ['', Validators.required],
      area: ['', Validators.required],
      contactName: ['', Validators.required],
      contactEmail: ['', [Validators.required, Validators.email]],
      contactPhone: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{10}$')],
      ],
      activities: [[], Validators.required],
    });

    this.userData = this.formBuilder.group({
      name: [''],
      email: [''],
    });

    this.logoData = this.formBuilder.group({
      logo: [''],
    });
  }

  async ngOnInit() {
    this.AggregatorList = await this.getAggregatorList();
    this.Activities = await this.getAllActivities();
  }

  async getAggregatorList(): Promise<any[]> {
    try {
      const res = await this.service.fetchAggregatorData();
      const aggregator = res.map((aggregator: AggregatorType) => ({
        ...aggregator,
        activities: aggregator.activities.split(',').map(Number),
        logoContent: this.convertLogo(aggregator.logoContent),
      }));
      if (res) this.isLoading = false;

      this.AggregatorListClone = aggregator;
      return aggregator;
    } catch (error: any) {
      this.isLoading = false;
      return [];
    }
  }

  private convertLogo(logoContent: any): any {
    try {
      if (logoContent.startsWith('/9j/')) {
        const base64Image = `data:image/jpeg;base64,${logoContent}`;
        return this.sanitizer.bypassSecurityTrustUrl(base64Image);
      } else {
        const decodedData = atob(logoContent);

        const blob = new Blob([decodedData], { type: 'image/svg+xml' });

        const url = URL.createObjectURL(blob);

        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    } catch (error) {
      console.error('Failed to decode base64 string', error);
    }
  }

  async getAllActivities(): Promise<any[]> {
    try {
      const res = await this.service.allSchemeActivitiesList();
      const activities = res.reduce((acc: any[], scheme: SchemeType) => {
        const schemeActivities = scheme.schemeActivities.map(
          (activity: any) => ({
            ...activity,
            schemeActivity: `${scheme.name} - ${activity.name}`,
          })
        );
        return acc.concat(schemeActivities);
      }, []);

      return activities;
    } catch (error: any) {
      return [];
    }
  }

  statusTag(status: string) {
    return status === 'Online' || status === 'ACTIVE'
      ? 'success'
      : status === 'INVITED'
      ? 'warning'
      : status === 'Offline'
      ? 'danger'
      : '';
  }

  async abnDetails(val: number) {
    const data = await lastValueFrom(this.api.getAbnData(val));
    return data;
  }

  async onEnter(event: any): Promise<void> {
    const value = event.target ? event.target.value : event;
    const data = await this.abnDetails(value);

    if (data.Message) {
      this.errorToast(data.Message);
      this.formData.patchValue({
        name: data.EntityName,
      });
    } else {
      this.formData.patchValue({
        name: data.EntityName,
      });
    }
  }

  async handleSearch(searchVal: string): Promise<void> {
    this.searchText = searchVal.trim();
    this.AggregatorList = this.AggregatorListClone.filter((item) =>
      item.name.toLowerCase().includes(searchVal.trim().toLowerCase())
    );
  }

  async handleAddDialog(): Promise<void> {
    this.formData.reset();
    this.imageUrl = null;
    this.display = true;
    this.edit = false;
  }

  handleHover(index: number): void {
    this.onHover = index;
  }

  async handleEdit(rowData: any): Promise<void> {
    this.rowData = rowData;
    this.edit = true;
    this.display = true;
    this.imageUrl = rowData.logoContent;
    this.formData.patchValue({
      ...rowData,
      ABN: rowData.abn,
      area: rowData.area.freeformAddress,
    });
  }

  async getAggregatorLogo(): Promise<any> {
    try {
      const res = await this.service.fetchAggregatorLogo(this.rowData.id);
      return res;
    } catch (error: any) {}
  }

  handleDelete(rowData: any): void {
    this.deleteDialog = true;
    this.rowData = rowData;
  }

  async onDeleteAggregator(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const res = await this.service.deleteAggregatorData(this.rowData.id);
      if (res) {
        this.AggregatorList = await this.getAggregatorList();
        this.handleSearch(this.searchText);
        this.deleteDialog = false;
        this.onSaveLoad = false;
        this.successToast('Aggregator deleted Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  closeDialog(): void {
    this.display = false;
    this.deleteDialog = false;
    this.inviteDialog = false;
    this.deleteUser = false;
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        ...this.formData.value,
        activities: this.formData.value.activities.join(','),
        area: this.formData.value.area?.address
          ? this.formData.value.area?.address
          : this.rowData?.area,
        Status: 'Offline',
      };
      const res = await this.service.createAggregatorData(payload);
      if (res && this.AggregatorLogo) {
        const payload = {
          AggregatorId: res.id,
          logo: this.logoData.value.logo,
        };
        const data = await this.service.createAggregatorLogo(payload);
        if (data) {
          this.AggregatorList = await this.getAggregatorList();
          this.handleSearch(this.searchText);
          this.display = false;
          this.onSaveLoad = false;
          this.successToast('Aggregator created Successfully');
        }
      } else {
        this.AggregatorList = await this.getAggregatorList();
        this.display = false;
        this.onSaveLoad = false;
        this.successToast('Aggregator created Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        ...this.formData.value,
        activities: this.formData.value.activities.join(','),
        Status: 'Offline',
        id: this.rowData.id,
        area: this.formData.value.area?.address
          ? this.formData.value.area?.address
          : this.rowData?.area,
      };
      const res = await this.service.updateAggregatorData(payload);
      if (res && this.AggregatorLogo) {
        const payload = {
          AggregatorId: this.rowData.id,
          logo: this.logoData.value.logo,
        };
        const data = await this.service.createAggregatorLogo(payload);
        if (data) {
          this.AggregatorList = await this.getAggregatorList();
          this.handleSearch(this.searchText);
          this.display = false;
          this.onSaveLoad = false;
          this.successToast('Aggregator Updated Successfully');
        }
      } else {
        this.AggregatorList = await this.getAggregatorList();
        this.handleSearch(this.searchText);
        this.display = false;
        this.onSaveLoad = false;
        this.successToast('Aggregator Updated Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  onSearchAddress(event: any): void {
    this.api.searchAddress(event.query).subscribe(
      (res: any) => {
        this.filteredAddress = res.results;
      },
      (error: any) => {
        console.error('Error fetching address:', error);
      }
    );
  }

  removeFile(): void {
    this.logoData.patchValue({ logo: '' });
    this.AggregatorLogo = null;
    this.imageUrl = null;
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0] as File;
    this.logoData.patchValue({ logo: file });
    this.imageUrl = URL.createObjectURL(file);
    this.AggregatorLogo = file.name || null;
  }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.logoData.patchValue({ logo: files });
      this.imageUrl = URL.createObjectURL(files[0]);
      this.AggregatorLogo = files[0].name;
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
  }

  handleInviteUser(rowData: AggregatorType): void {
    this.inviteDialog = true;
    this.rowData = rowData;
    this.userData.reset();
  }

  async onInvite(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        ...this.userData.value,
        status: 'Invited',
      };
      const res = await this.service.inviteAggregatorUser(
        this.rowData.id,
        payload
      );
      if (res) {
        this.onSaveLoad = false;
        this.successToast('User Invited Successfully');
        this.userData.reset();
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.error.error.message);
    }
  }

  async onExpand(rowData: AggregatorType): Promise<void> {
    this.rowData = rowData;
    try {
      const res = await this.service.fetchAggregatorUser(rowData.id);
      if (res) {
        this.userList = res;
      }
    } catch (error: any) {
      this.errorToast(error.message);
    }
  }

  handleUserDelete(rowData: any): void {
    this.userRowData = rowData;
    this.deleteUser = true;
  }

  async onDeleteUser(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const res = await this.service.deleteAggregatorUser(
        this.rowData.id,
        this.userRowData.id
      );
      if (res) {
        const data = await this.service.fetchAggregatorUser(this.rowData.id);
        if (data) {
          this.userList = data;
        }
        this.deleteUser = false;
        this.onSaveLoad = false;
        this.successToast('User deleted Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { SharedModule } from '../../shared.module';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [InputTextModule, SharedModule, ReactiveFormsModule],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss',
})
export class InputComponent {
  @Input() label: string = '';
  @Input() error: string = '';
  @Input() placeholder: string = '';
  @Input() required: boolean = false;
  @Input() control!: any;
  @Input() disabled: boolean = false;
}

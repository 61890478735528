import { Component } from '@angular/core';
import { ButtonComponent } from '../../Components/button/button.component';
import { SharedModule } from '../../shared.module';
import { SearchFieldComponent } from '../../Components/searchField/search-text.component';
import { InputComponent } from '../../Components/input/input.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DropDownType, TableCols } from '../../types';
import {
  FORM_STAGE_LIST,
  FORM_TYPE_LIST,
  FORMS_COLS,
  LICENCE_LIST,
  REGULATOR_LIST,
  STATE_LIST,
} from '../../../constants';
import { DropdownComponent } from '../../Components/dropdown/dropdown.component';
import { FormsService } from './forms.service';

@Component({
  selector: 'app-forms',
  standalone: true,
  imports: [
    ButtonComponent,
    SharedModule,
    SearchFieldComponent,
    InputComponent,
    DropdownComponent,
  ],
  templateUrl: './forms.component.html',
  styleUrl: './forms.component.scss',
})
export class FormsComponent {
  cols: TableCols[] = FORMS_COLS;
  FormsList: any[] = [];
  onHover: number = 0;
  display: boolean = false;
  edit: boolean = false;
  selectedFileName: string | null = null;
  isLoading: boolean = true;
  formData!: FormGroup;
  skeletonRows = new Array(10);
  deleteDialog: boolean = false;
  RowData!: any;
  onSaveLoad: boolean = false;
  typeList: DropDownType[] = FORM_TYPE_LIST;
  regulatorList: DropDownType[] = REGULATOR_LIST;
  stageList: DropDownType[] = FORM_STAGE_LIST;
  licenseType: DropDownType[] = LICENCE_LIST;
  stateList: DropDownType[] = STATE_LIST;
  formFields: any[] = [];
  formListClone: any[] = [];
  searchText: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private service: FormsService
  ) {
    this.formData = this.formBuilder.group({
      name: ['', Validators.required],
      type: ['', Validators.required],
      regulator: [''],
      stage: [''],
      licenseType: [''],
      state: [''],
      fieldIds: [''],
    });
  }

  async ngOnInit() {
    this.FormsList = await this.getFormsData();
    this.formFields = await this.getFormFieldsData();
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  handleDownload(): void {}

  errorToast(detail: string): void {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: detail,
    });
  }

  successToast(detail: string): void {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: detail,
    });
  }

  handleNewUpload(): void {
    this.display = true;
    this.selectedFileName = null;
    this.edit = false;
    this.formData.reset();
  }

  private async getFormsData(): Promise<any[]> {
    try {
      const data = await this.service.fetchFormsData();
      if (data) {
        this.formListClone = data;
        this.isLoading = false;
      }
      return data;
    } catch (error: any) {
      this.isLoading = false;
      return [];
    }
  }

  private async getFormFieldsData(): Promise<any[]> {
    try {
      const data = await this.service.fetchFormFieldsData();
      if (data) {
        this.isLoading = false;
      }
      return this.sortFormFields(data);
    } catch (error: any) {
      this.isLoading = false;
      return [];
    }
  }

  private sortFormFields(data: any[]): any[] {
    return data.slice().sort((a, b) => a.code.localeCompare(b.code));
  }

  handleEdit(rowData: any): void {
    this.display = true;
    this.edit = true;
    this.formData.patchValue({
      ...rowData,
      regulator: rowData?.regulator ?? null,
      stage: rowData?.stage ?? null,
      licenseType: rowData?.licenseType ?? null,
      state: rowData?.state ?? null,
      fieldIds: rowData.fields
        ? rowData.fields.map((c: any) => c.fieldId)
        : null,
    });
    this.RowData = rowData;
  }

  closeDialog(): void {
    this.display = false;
    this.edit = false;
    this.deleteDialog = false;
  }

  async onSave(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const data = await this.service.createFormData(this.formData.value);
      if (data) {
        this.onSaveLoad = false;
        this.display = false;
        this.FormsList = await this.getFormsData();
        this.handleSearch(this.searchText);
        this.successToast('Form Created Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async onUpdate(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const payload = {
        id: this.RowData.id,
        ...this.formData.value,
      };
      const data = await this.service.updateFormData(payload);
      if (data) {
        this.onSaveLoad = false;
        this.display = false;
        this.FormsList = await this.getFormsData();
        this.handleSearch(this.searchText);
        this.successToast('Form Updated Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  handleDelete(rowData: any): void {
    this.RowData = rowData;
    this.deleteDialog = true;
  }

  async onDelete(): Promise<void> {
    this.onSaveLoad = true;
    try {
      const data = await this.service.deleteFormData(this.RowData.id);
      if (data) {
        this.onSaveLoad = false;
        this.deleteDialog = false;
        this.FormsList = await this.getFormsData();
        this.handleSearch(this.searchText);
        this.successToast('Form Deleted Successfully');
      }
    } catch (error: any) {
      this.onSaveLoad = false;
      this.errorToast(error.message);
    }
  }

  async handleSearch(searchVal: string): Promise<void> {
    this.searchText = searchVal.trim();
    this.FormsList = this.formListClone.filter(
      (item) =>
        item.name.toLowerCase().includes(searchVal.trim().toLowerCase()) ||
        item.type.toLowerCase().includes(searchVal.trim().toLowerCase())
    );
  }
}

<div class="page-container activities-container">
  <p-toast />
  <div class="header-container">
    <div class="title">
      <i
        class="pi pi-arrow-left"
        style="font-size: 1rem; cursor: pointer"
        (click)="navigateBack()"
      ></i>
      <div class="header">Activities</div>
    </div>
    <div style="display: flex; gap: 2rem">
      <app-search-text (searchEvent)="handleSearch($event)" />
      <app-button label="Add" (clickEvent)="handleAddNew()" />
    </div>
  </div>

  <p-table
    [columns]="cols"
    [value]="activitiesList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th>Name</th>
        <th>Code</th>
        <th>Registration Code</th>
        <th>Device Type</th>
        <th>Dwelling Type</th>
        <th>Replace / New</th>
        <th style="text-align: end">Minimum Co-Payment</th>
        <th>Calculation Method</th>
        <th></th>
      </tr>
      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td class="activity-name" (click)="nagivateToFields(rowData)">
          {{ rowData.name }}
        </td>
        <td>{{ rowData?.code }}</td>
        <td>{{ rowData?.registrationCode }}</td>
        <td>{{ rowData.deviceType }}</td>
        <td>{{ rowData.dwellingType }}</td>
        <td>{{ rowData.isReplace ? "Replace" : "New" }}</td>
        <td style="text-align: end">
          {{ rowData.minimumCoPayment | currency : "USD" }}
        </td>
        <td>{{ rowData.calculationMethod }}</td>
        <td (click)="op.toggle($event)">
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-overlayPanel #op>
            <ng-template pTemplate="content">
              <div
                (mouseover)="handleHover(1)"
                (click)="handleEdit(rowData)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Edit</span>
              </div>
              <div
                (mouseover)="handleHover(2)"
                (click)="handleDelete(rowData)"
                class="list-item"
                [class.hover-bg-light-red]="onHover === 2"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Delete</span>
              </div>
            </ng-template>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="activitiesList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="5">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog
    header="Header"
    [(visible)]="display"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '50vw' }"
  >
    <ng-template pTemplate="header">
      <h3>{{ editActivity ? "Edit Activites" : "Add Activites" }}</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="formData">
        <div class="grid-container">
          <app-input
            label="Name"
            [control]="formData.get('name')"
            [required]="true"
          />
          <div *ngIf="!editActivity" class="category">
            <div *ngFor="let category of categories">
              <p-radioButton
                [inputId]="category.value"
                [value]="category"
                formControlName="isReplace"
              />
              <label class="input-label" [for]="category.value" class="ml-2">
                {{ category.label }}
              </label>
            </div>
          </div>
          <app-input
            *ngIf="!editActivity"
            label="Code"
            [control]="formData.get('code')"
            [required]="true"
          />
          <app-input
            *ngIf="!editActivity"
            label="Registration Code"
            [control]="formData.get('registrationCode')"
          />
          <app-dropdown
            *ngIf="!editActivity"
            [options]="deviceList"
            [control]="formData.get('deviceType')"
            label="Device Type"
            placeholder="Select"
            optionLabel="label"
            optionValue="value"
            [required]="true"
          />
          <app-dropdown
            *ngIf="!editActivity"
            [options]="dwellingList"
            [control]="formData.get('dwellingType')"
            label="Dwelling Type"
            placeholder="Select"
            optionLabel="label"
            optionValue="value"
          />
          <app-number-input
            [control]="formData.get('minimumCoPayment')"
            inputId="withoutgrouping"
            mode="decimal"
            [useGrouping]="false"
            label="Minimum Co-Payment"
            prefix="$"
            [required]="true"
          />
          <app-input
            label="Calculation Method"
            [control]="formData.get('calculationMethod')"
            [required]="true"
          />
          <!-- <div>
            <label class="input-label">
              Licence
              <span class="required-label">*</span>
            </label>
            <p-multiSelect
              [options]="licenceList"
              formControlName="licence"
              optionLabel="label"
              placeholder="Select"
              appendTo="body"
              optionValue="label"
            />
            <div
              class="error-message"
              *ngIf="
                formData.get('licence')?.invalid &&
                formData.get('licence')?.touched
              "
            >
              Licence is required.
            </div>
          </div> -->
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        [label]="editActivity ? 'Update' : 'Save'"
        [disabled]="formData.invalid"
        (clickEvent)="editActivity ? onUpdate() : onSave()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="deleteActivtyDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="content">
      <h1>Are you sure you want to delete this Activity?</h1>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        label="Delete"
        (clickEvent)="onDeleteActivity()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>
</div>

<p-toast />
<div class="page-container manage-zone-page">
  <div class="header-container">
    <app-search-text (searchEvent)="handleSearch($event)" class="header" />
    <app-button label="Add" (clickEvent)="handleAddDialog()" class="header" />
  </div>

  <p-table
    [columns]="cols"
    [value]="ManageZoneList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>
      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>

    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr *ngFor="let postalCode of rowData.zoneValues; let isFirstRow = first">
        <td *ngIf="isFirstRow" [attr.rowspan]="rowData.zoneValues.length">
          {{ rowData.name }}
        </td>
        <td *ngIf="isFirstRow" [attr.rowspan]="rowData.zoneValues.length">
          <div *ngIf="rowData.state">
            <img src="assets/locationIcon.svg" style="margin-right: 5px" />
            {{ rowData?.state }}
          </div>
          <div *ngIf="!rowData.state">-</div>
        </td>
        <td>
          <div *ngFor="let code of postalCode.postCodes">
            <div style="padding: 0.3rem 0; font-size: 0.9rem">
              {{ code.from }} to {{ code.to }}
            </div>
          </div>
        </td>
        <td>
          <img src="assets/CalendarIcon.svg" style="margin-right: 5px" />
          {{ postalCode.dateFrom | date : "yyyy-MM-dd" }}
        </td>
        <td (click)="op.toggle($event)">
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-overlayPanel #op>
            <ng-template pTemplate="content">
              <div
                (mouseover)="handleHover(1)"
                (click)="handleEdit(postalCode, rowData)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Amend Postal code</span>
              </div>
              <div
                (mouseover)="handleHover(2)"
                (click)="handleDelete(rowData, postalCode)"
                class="list-item"
                [class.hover-bg-light-red]="onHover === 2"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Delete</span>
              </div>
            </ng-template>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" *ngIf="ManageZoneList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="5">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <!-- Dialog Component -->
  <p-dialog
    header="Header"
    [(visible)]="display"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header">
      <h3>{{ editZone ? "Amend Postal code" : "Add Zone" }}</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="formData">
        <div *ngIf="!editZone" class="grid-container">
          <app-input
            label="Name"
            [control]="formData.get('name')"
            [required]="true"
          />
          <app-dropdown
            [options]="stateList"
            [control]="formData.get('state')"
            label="State"
            placeholder="Select"
            optionLabel="label"
            optionValue="value"
          />
        </div>
        <div style="margin-bottom: 1rem">
          <label class="input-label">
            Postal Code
            <div class="required-label">*</div>
          </label>
          <div
            *ngFor="let range of postalCodeControls; let i = index"
            class="postal-code-range"
          >
            <div class="flex">
              <app-number-input
                [control]="range.get('from')"
                inputId="withoutgrouping"
                mode="decimal"
                [useGrouping]="false"
                error="From"
                placeholder="From"
              />
              <span style="margin: 0.6rem">-</span>
              <app-number-input
                [control]="range.get('to')"
                inputId="withoutgrouping"
                mode="decimal"
                [useGrouping]="false"
                error="To"
                placeholder="To"
              />
              <span
                class="pi pi-times"
                style="color: #e71d36; margin-left: 1rem; margin-top: 1rem"
                (click)="removePostalCode(i)"
              ></span>
            </div>
          </div>
          <div
            *ngIf="formData.get('postalCode')?.hasError('overlappingCodes')"
            class="error-message"
          >
            Postal codes cannot overlap.
          </div>
          <app-button label="Add Postal Code" (clickEvent)="addPostalCode()" />
        </div>
        <div class="grid-container">
          <app-calendar
            label="Date"
            [control]="formData.get('date')"
            [required]="true"
          />
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        *ngIf="!editZone"
        label="Save"
        [disabled]="formData.invalid"
        (clickEvent)="onSave()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
      <app-button
        *ngIf="editZone"
        label="Update"
        (clickEvent)="onAmend()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>

  <!-- Delete Confirmation Dialog -->

  <p-dialog
    header="Header"
    [(visible)]="deleteDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="content">
      <h1>Are you sure you want to delete this Zone ?</h1>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        label="Delete"
        (clickEvent)="onDelete()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarModule } from 'primeng/calendar';
import { SharedModule } from '../../shared.module';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
@Component({
  selector: 'app-calendar',
  standalone: true,
  imports: [CalendarModule, SharedModule, ReactiveFormsModule],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss',
})
export class CalendarComponent {
  @Input() selectedDate: Date | string = '';
  @Input() label: string = '';
  @Input() required: boolean = false;
  @Input() error: string = '';

  @Input() control!: any;
}

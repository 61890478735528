<p-toast />
<div class="page-container form-document">
  <div class="header-container">
    <app-search-text class="header" (searchEvent)="handleSearch($event)" />
    <app-button
      label="Add"
      (clickEvent)="handleNewUpload()"
      class="header"
    />
  </div>

  <p-table
    [columns]="cols"
    dataKey="id"
    [value]="FormsList"
    [paginator]="true"
    [rows]="10"
    [showCurrentPageReport]="true"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rowsPerPageOptions]="[5, 10, 20]"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns">{{ col.header }}</th>
      </tr>

      <ng-container *ngIf="isLoading">
        <tr *ngFor="let _ of skeletonRows">
          <th class="skeleton" *ngFor="let col of columns">
            <p-skeleton></p-skeleton>
          </th>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr>
        <td style="font-weight: 500">{{ rowData.name }}</td>
        <td>{{ rowData?.type }}</td>
        <td>{{ rowData?.regulator ?? "-" }}</td>
        <td>{{ rowData?.stage ?? "-" }}</td>
        <td>{{ rowData?.licenseType ?? "-" }}</td>
        <td>{{ rowData?.state ?? "-" }}</td>
        <td (click)="op.toggle($event)">
          <img src="assets/ActionMenu.svg" style="cursor: pointer" />
          <p-overlayPanel #op>
            <ng-template pTemplate="content">
              <div
                (mouseover)="handleHover(1)"
                (click)="handleEdit(rowData)"
                class="list-item"
                [class.hover-bg-light-blue]="onHover === 1"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-user-edit" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Edit</span>
              </div>

              <div
                (mouseover)="handleHover(3)"
                class="list-item"
                (click)="handleDelete(rowData)"
                [class.hover-bg-light-red]="onHover === 3"
                (mouseleave)="onHover = 0"
              >
                <i class="pi pi-trash" style="font-size: 1.3rem"></i>
                <span style="font-size: 14px">Delete</span>
              </div>
            </ng-template>
          </p-overlayPanel>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage" *ngIf="FormsList.length === 0">
      <tr *ngIf="!isLoading">
        <td colspan="5">No Data found.</td>
      </tr>
    </ng-template>
  </p-table>

  <p-dialog
    header="Header"
    [(visible)]="display"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '30vw' }"
  >
    <ng-template pTemplate="header">
      <h3>{{ edit ? "Edit" : "Add" }}</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form class="dialog-form" [formGroup]="formData">
        <div class="grid-container">
          <app-input
            label="Name"
            [control]="formData.get('name')"
            [required]="true"
          />
          <app-dropdown
            [options]="typeList"
            [control]="formData.get('type')"
            label="Type"
            placeholder="Select"
            optionLabel="label"
            optionValue="value"
            [required]="true"
          />
          <app-dropdown
            [options]="regulatorList"
            [control]="formData.get('regulator')"
            label="Regulator"
            placeholder="Select"
            optionLabel="label"
            optionValue="value"
          />
          <app-dropdown
            [options]="stageList"
            [control]="formData.get('stage')"
            label="Stage"
            placeholder="Select"
            optionLabel="label"
            optionValue="value"
          />
          <app-dropdown
            [options]="licenseType"
            [control]="formData.get('licenseType')"
            label="License Type"
            placeholder="Select"
            optionLabel="label"
            optionValue="value"
          />
          <app-dropdown
            [options]="stateList"
            [control]="formData.get('state')"
            label="State"
            placeholder="Select"
            optionLabel="label"
            optionValue="value"
          />
        </div>
        <div>
          <label class="input-label">Form Fields</label>
          <p-multiSelect
            [options]="formFields"
            formControlName="fieldIds"
            optionLabel="code"
            placeholder="Select"
            appendTo="body"
            optionValue="id"
          />
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        *ngIf="!edit"
        label="Save"
        [disabled]="formData.invalid"
        (clickEvent)="onSave()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
      <app-button
        *ngIf="edit"
        label="Update"
        [disabled]="formData.invalid"
        (clickEvent)="onUpdate()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>

  <p-dialog
    header="Header"
    [(visible)]="deleteDialog"
    [modal]="true"
    [closable]="false"
    [style]="{ width: '32vw' }"
  >
    <ng-template pTemplate="header"> </ng-template>
    <ng-template pTemplate="content">
      <h1>Are you sure you want to delete this Form ?</h1>
    </ng-template>
    <ng-template pTemplate="footer">
      <app-button
        label="Cancel"
        (clickEvent)="closeDialog()"
        className="cancel-dialog"
        style="margin-right: 1rem"
      />
      <app-button
        label="Delete"
        (clickEvent)="onDelete()"
        [icon]="onSaveLoad ? 'pi pi-spin pi-spinner' : ''"
      />
    </ng-template>
  </p-dialog>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TagModule } from 'primeng/tag';
import { SkeletonModule } from 'primeng/skeleton';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfigService } from './Services/config-service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    TableModule,
    DialogModule,
    OverlayPanelModule,
    ReactiveFormsModule,
    MultiSelectModule,
    AutoCompleteModule,
    RadioButtonModule,
    TagModule,
    SkeletonModule,
    ToastModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    TableModule,
    DialogModule,
    OverlayPanelModule,
    ReactiveFormsModule,
    MultiSelectModule,
    AutoCompleteModule,
    RadioButtonModule,
    TagModule,
    SkeletonModule,
    ToastModule,
  ],
  providers: [
    MessageService,
    ConfirmationService,
    ConfigService,
  ],
})
export class SharedModule {}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableCols } from '../../../../types';
import { MANAGE_FIELD_PICKLIST } from '../../../../../constants';
import { SharedModule } from '../../../../shared.module';
import { DropdownComponent } from '../../../../Components/dropdown/dropdown.component';
import { CalendarComponent } from '../../../../Components/calendar/calendar.component';
import { InputComponent } from '../../../../Components/input/input.component';
import { ButtonComponent } from '../../../../Components/button/button.component';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MANAGE_ZONE, PICKLIST_DATA } from '../../../../../mock-data';
import { DataService } from '../../manage-zone/api.services';

@Component({
  selector: 'app-picklist',
  standalone: true,
  templateUrl: './picklist.component.html',
  styleUrl: './picklist.component.scss',
  imports: [
    SharedModule,
    DropdownComponent,
    CalendarComponent,
    InputComponent,
    ButtonComponent,
  ],
})
export class PicklistTableComponent {
  Cols: TableCols[] = MANAGE_FIELD_PICKLIST;
  tableData: any[] = [];
  zoneList: any[] = [];
  onHover: number = 0;
  editValue: boolean = false;
  rowIndex: number = 0;
  Picklist: any[] = PICKLIST_DATA;

  formData!: FormGroup;

  @Input() picklistValue: any[] = [];

  @Output() picklistData = new EventEmitter<any[]>();

  constructor(
    private formBuilder: FormBuilder,
    private zoneService: DataService
  ) {
    this.formData = this.formBuilder.group({
      zone: [''],
      date: ['', Validators.required],
      values: this.formBuilder.array([this.createValuesGroup()]),
    });
  }

  async ngOnInit() {
    let fetchedZoneList = await this.zoneService.fetchZoneData();

    const defaultZone = {
      id: null,
      name: 'All Zone',
      state: '',
      createdAt: '',
      updatedAt: '',
      zoneValues: [],
    };
    fetchedZoneList.unshift(defaultZone);

    this.zoneList = fetchedZoneList;
  }

  resetForm() {
    this.formData.reset();
    const data = this.formData.get('values') as FormArray;
    data.clear();
    data.push(
      this.formBuilder.group({
        key: '',
        value: '',
      })
    );
  }

  handleAddNew(): void {
    this.picklistValue.push(this.formData.value);
    this.picklistData.emit(this.picklistValue);
    this.resetForm();
  }

  get valuesControls() {
    return (this.formData.get('values') as FormArray).controls;
  }

  get addButtonDisabled() {
    return this.valuesControls.some(
      (control) =>
        control.get('key')?.value === '' || control.get('value')?.value === ''
    );
  }

  handleHover = (index: number) => {
    this.onHover = index;
  };

  createValuesGroup() {
    return this.formBuilder.group({
      key: ['', Validators.required],
      value: ['', Validators.required],
    });
  }

  addKeyValue() {
    const value = this.formData.get('values') as FormArray;
    value.push(this.createValuesGroup());
  }

  removeKeyValue(index: number) {
    const value = this.formData.get('values') as FormArray;
    value.removeAt(index);
  }

  handleEdit(rowData: any, index: number): void {
    this.editValue = true;
    this.rowIndex = index;
    this.formData.patchValue({
      zone: rowData.zone,
      date: rowData.date,
    });
    const data = this.formData.get('values') as FormArray;
    data.clear();

    rowData.values.map((ele: any) =>
      data.push(
        this.formBuilder.group({
          key: ele.key,
          value: ele.value,
        })
      )
    );
  }

  handleDelete(i: number): void {
    this.picklistValue.splice(i, 1);
    this.picklistData.emit(this.picklistValue);
  }

  handleUpdate(): void {
    this.picklistValue[this.rowIndex] = this.formData.value;
    this.editValue = false;
    this.picklistData.emit(this.picklistValue);
    this.resetForm();
  }
}
